.App {
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #303547;
}

.MuiButtonBase-root {
  color: white !important;
}

.MuiBox-root {
  background: transparent !important;
}

.PhoneInputInput {
  color: white !important;
  background: rgb(37, 41, 46);
  height: 42px;
  border-color: white;
  border-radius: 4px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
}

.ellipse-image-content {
  text-align: center;
  width: 56px;
  height: 56px;
  padding: 17px 17px 16px 16px;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  border: solid 1px #fff;
  background-image: linear-gradient(to bottom, #242532, #0b0d1c);
  border-radius: 50%;
  box-shadow: 0 0 8px 8px rgba(118, 184, 199, 0.24);
  pointer-events: none;
}

.first-last-name {
  width: 173px;
  height: 19px;
  margin: 21px 37px 7px 36px;
  margin-right: auto;
  margin-left: auto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.company-name-inc {
  white-space: pre-wrap;
  width: 142px;
  margin: 7px 52px 16px;
  margin-right: auto;
  margin-left: auto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.admin-rectangle {
  text-align: center;
  width: 54px;
  height: 20px;
  margin: 16px 96px 35px;
  margin-right: auto;
  margin-left: auto;
  padding: 2px 6px 3px;
  border-radius: 1px;
  border: solid 1px #76b8c7;
  background-color: rgba(118, 184, 199, 0.17);
}

[data-testid="FileDownloadOutlinedIcon"] {
  color: #fff !important;
  margin-left: -60px;
}

[data-testid="ManageAccountsOutlinedIcon"] {
  color: #a7d541 !important;
  margin-left: -60px;
}

[data-value="Manage Users"] {
  display: none !important;
}
[data-value="Status"] {
  display: none !important;
}
[data-value="Plan"] {
  display: none !important;
}
[data-value="Synchrony"] {
  display: none !important;
}

.account-full-name {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  margin-top: -1px;
}

.account-manager {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.horisontal-line {
  height: 0;
  margin: 5px 35px 0 35px;
  border: solid 1px rgba(255, 255, 255, 0.13);
  margin-bottom: 10px;
}

.account-contact-details {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  margin-top: 4px;
  text-decoration: underline;
}

.partial-title {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.partial-subtitle {
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.team-details-section-selected {
  cursor: pointer;
  height: 30px;
  padding: 16px 30px 15px 28px;
  border-radius: 6px;
  border: solid 1px #a7d541;
  background-color: rgba(10, 11, 18, 0.38);
  margin-bottom: 15px;
}

.team-details-section {
  cursor: pointer;
  height: 30px;
  padding: 16px 30px 15px 28px;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(10, 11, 18, 0.38);
  margin-bottom: 15px;
}

.team-details-number {
  width: 85px;
  height: 52px;
  float: left;
  margin-top: -10px;
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.reports-details-number {
  width: 85px;
  height: 52px;
  float: right;
  margin-top: -10px;
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
}

.team-details-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.team-details-icon {
  font-size: 23px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  float: right;
  color: #fff;
  margin-top: 5px;
}

.MuiInputBase-input {
  color: white !important;
}

.MuiFormLabel-root {
  color: white !important;
}

.MuiInputLabel-asterisk {
  color: red !important;
}

.portal-custom-btn {
  width: "auto";
  height: 40px;
  background: rgb(17, 18, 26);
  margin-top: 10px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.modal-title {
  text-align: center;
}

.portal-custom-modal-btn {
  text-align: center;
  width: "auto";
  height: 40px;
  background: rgb(17, 18, 26);
  margin-top: 10px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.portal-custom-btn-dropdown {
  width: 140px;
  height: 40px;
  background: rgb(17, 18, 26);
  margin-top: 10px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.delete-user-btn-dropdown {
  text-align: center;
  width: 140px;
  height: 40px;
  background: #f48a48;
  margin-top: 10px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
}

.portal-custom-btn-text {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.delete-user-btn-text {
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}

.portal-custom-btn-icon {
  cursor: pointer;
  float: right;
}

.portal-custom-btn-icon svg {
  float: right;
}

.Mui-checked {
  color: #a7d541 !important;
}

.upload-files-zone {
  margin-top: 25px;
  text-align: center;
  border-style: dotted;
  border-color: #707070;
  padding: 20px;
  font-size: 14px;
  font-weight: bold;
  cursor: copy;
}

.browse-files {
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

.users-filters {
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  background-color: rgba(10, 11, 18, 0.38);
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 40px;
}

.MuiOutlinedInput-root {
  background: rgb(37, 41, 46);
  height: 40px;
}

.MuiList-root {
  background: #25292e !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid transparent !important;
}

.welcome-msg-app-bar {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  font-weight: bold;
  margin-left: 35px;
}

.app-bar-btns {
  position: absolute;
  right: 200px;
  top: 17px;
  display: inline-flex;
}

.app-bar-btn {
  border-radius: 6px;
  background-color: #fff;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

.app-bar-btn-synchrony {
  position: absolute;
  border-radius: 6px;
  background-color: #fff;
  height: 30px;
  width: 30px;
  cursor: pointer;
  left: 15px;
  top: -10px;
}

.app-bar-btn svg {
  color: #fcc701;
  font-size: 30px;
}

.login-screen {
  text-align: center;
  height: 100vh;
  background-color: #11121a;
}

.login-btns {
  position: absolute;
  top: 45%;
  left: 50%;
  margin-top: 40px;
  margin-left: -100px;
  background: #25292e;
  width: 200px;
  border-radius: 6px;
  cursor: pointer;
  text-align: center;
  padding: 20px 5px 20px;
}

.login-btns-second {
  position: absolute;
  top: 55%;
}

.login-screen-logo {
  width: 400px;
  margin-top: 35vh;
}

.login-btns span {
  vertical-align: 4px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.logout-icon {
  margin-top: 8px;
  margin-right: 10px;
}

.loader-container {
  z-index: 2;
  height: 100vh;
  width: 100vw;
}

.not-found {
  text-align: center;
  height: 100vh;
  margin-top: -20px;
}

.not-found-message {
  padding-top: 20%;
}

.bolder {
  font-weight: bolder;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="col-"] {
  float: left;
  padding: 10px;
}

.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.66%;
}
.col-12 {
  width: 100%;
}

/* For mobile phones: */
[class*="col-"] {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-35 {
  margin-top: 35px;
}

.m-t-40 {
  margin-top: 40px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.alerts-border {
  border: 2px transparent solid;

  animation: blink 1s;
  animation-iteration-count: 5;
}

.csv-instructions-title {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding-top: 0px;
  margin-top: 0px;
}

.csv-instructions-content {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 0px;
  margin: 0px;
}

.pending-message {
  margin-left: 5px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b5b5b5;
  cursor: pointer;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.align-center {
  text-align: center;
}

.report-container {
  background-color: #3d455c;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
  height: "70%";
}

.border-bottom {
  border-bottom: 2px solid #53596e;
}

.border-right {
  border-right: 2px solid #53596e;
}

.padding-top-10 {
  padding-top: 10px;
}
.padding-top-20 {
  padding-top: 20px;
}

.report-small-title {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #cadcff;
}

.color-red {
  color: red;
}

.color-green {
  color: #a7d541;
}

.color-yellow {
  color: yellow;
}

.color-blue {
  color: #346bd5;
}

.color-red svg {
  margin-left: 5px;
  margin-bottom: -5px;
}

.color-green svg {
  margin-left: 5px;
  margin-bottom: -5px;
}

.color-yellow svg {
  margin-left: 5px;
  margin-bottom: -5px;
}

@keyframes blink {
  50% {
    border-color: #a7d541;
  }
}

.icons-container {
  position: relative;
  background-color: #346bd5;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  padding: 4px;
}
.report-icons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.phone-number-form .PhoneInputInput {
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  border-bottom: 1px solid white;
}

.phone-number-form .PhoneInput--focus {
  border-bottom: 2px solid #a7d541;
}

.phone-number-form .PhoneInput--focus .PhoneInputInput {
  outline-color: transparent;
  outline: none;
  border-bottom: none;
}

.white {
  color: white;
}

.padding-left-30 {
  padding-left: 30px;
}
.padding-right-30 {
  padding-right: 30px;
}
