.wrapper {
    border: 2px solid #53596e;
    height: 35px;
    border-radius: 5px;
    margin: 10px
}
.container {
    background-color: #3d455c;
    border-radius: 5px;
    height: 35px;
}
.container>div{
    background-color: #346bd5 !important;
    border-radius: 5px !important;
    height: 35px !important;
}

.label {
    font-size: 20px;
    color: white;
}
.wrapper-vertical {
    border: 2px solid #53596e;
    height: 20vw !important;
    border-radius: 5px;
    width: 360px;
    margin-left: 80px;
}
.container-vertical {
    background-color: #3d455c;
    border-radius: 5px;
    height: 100%;
}
.container-vertical>div{
    background-color: #346bd5 !important;
    border-radius: 5px !important;
    height: 100% !important;
}

.label-vertical {
    font-size: 20px;
    color: white;
    transform: rotate(90deg);
    margin-right: 5px;
}
